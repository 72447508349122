/* eslint-disable */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { navigate } from '@reach/router';
import { FormattedMessage } from 'react-intl';
import * as Component from '../components';
import * as Helper from '../helpers';

function Home({
  code,
  language,
  expired,
  thankYou,
}) {
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState(0);
  const [pickupTime, setPickupTime] = useState(Helper.Time.suggestedPickup);
  const [returnTime, setReturnTime] = useState(Helper.Time.suggestedReturn);
  const [alert, setAlert] = useState({ text: null, type: null });
  const [vehicles, setVehicles] = useState([]);
  const [highlightVehicles, setHighlightVehicles] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const resultCategories = await Helper.Api.get('categories');
        setCategories(resultCategories.data);
        const resultVehicles = await Helper.Api.get('vehicles', { params: { random: true } });
        setVehicles(resultVehicles.data);
        const resultHighlightVehicles = await Helper.Api.get('vehicles', { params: { infoPrice: true } });
        setHighlightVehicles(resultHighlightVehicles.data.sort((prevVehicle, nextVehicle) => {
          const prevMinPrice = prevVehicle.prices.length
            ? (prevVehicle.prices.reduce(
              (min, prevPrice) => Math.min(min, prevPrice.value),
              Infinity,
            )) : prevVehicle.price;
          const nextMinPrice = nextVehicle.prices.length
            ? (nextVehicle.prices.reduce(
              (min, prevPrice) => Math.min(min, prevPrice.value),
              Infinity,
            )) : nextVehicle.price;

          if (prevMinPrice < nextMinPrice) return -1;
          if (prevMinPrice > nextMinPrice) return 1;
          return 0;
        }));
        setLoading(false);
      } catch (error) {
        // eslint-disable-next-line
        console.log(error);
      }
    })();
  }, []);

  const onSubmit = (event) => {
    event.preventDefault();
    if (!Helper.Time.isReturnAfterPickup(pickupTime, returnTime)) {
      setAlert({
        type: 'danger',
        text: 'error.returnBeforePickup',
      });
    }
    // else if (!Helper.Time.isTimeSelected(pickupTime)) {
    //   setAlert({
    //     type: 'danger',
    //     text: 'error.isTimeSelected',
    //   });
    // } 
    else {
      navigate(`/search/pickup/${new Date(pickupTime).getTime()}/return/${new Date(returnTime).getTime()}/category/${category}`);
    }
  };

  if (loading) return <Component.Loader />;

  return (
    <>

      <Component.Scroll />
      <Component.Hero code={code} expired={expired} thankYou={thankYou} />

      <form onSubmit={onSubmit}>
        <Component.Container grid search>

          {/* <Component.Grid>
            <h1 className="title" style={{ margin: '2rem 0 0 0' }}>
              <FormattedMessage id="title.search" />
            </h1>
          </Component.Grid> */}

          {alert.text && (
            <Component.Grid>
              <Component.Alert type={alert.type} text={alert.text} />
            </Component.Grid>
          )}

          <Component.Grid medium={6} large={4}>
            <FormattedMessage id="rent.pickupTime">
              {(label) => (
                <Component.DateTime
                  name="pickupTime"
                  label={label}
                  time={pickupTime}
                  setTime={setPickupTime}
                />
              )}
            </FormattedMessage>
          </Component.Grid>

          <Component.Grid medium={6} large={4}>
            <FormattedMessage id="rent.returnTime">
              {(label) => (
                <Component.DateTime
                  name="returnTime"
                  label={label}
                  time={returnTime}
                  setTime={setReturnTime}
                />
              )}
            </FormattedMessage>
          </Component.Grid>

          <Component.Grid medium={6} large={2}>
            <FormattedMessage id="rent.vehicleCategory">
              {(label) => (
                <Component.Select
                  value={category}
                  name="category"
                  label={label}
                  options={categories}
                  onChange={setCategory}
                />
              )}
            </FormattedMessage>
          </Component.Grid>

          <Component.Grid medium={6} large={2}>
            <FormattedMessage id="rent.search">
              {(label) => <Component.Button block>{label}</Component.Button>}
            </FormattedMessage>
          </Component.Grid >
        </Component.Container>
      </form>

      <Component.Container>
        <Component.Grid medium={6} large={2}>
          <section className="longterm">
            <h2><FormattedMessage id="navigation.longTermRental" /></h2>
            <p>
              <FormattedMessage id="home.hero.longTerm.subhead" />
            </p>
            <button>
              <a href="long-term-rental"><FormattedMessage id="home.findOutMore" /></a>
            </button>
          </section>
        </Component.Grid>
      </Component.Container>

      <Component.Grid>
        <section id="vehicles">
          <h2><FormattedMessage id="vehicles.headline" /></h2>
          <div className="cards">
            <div className="card">
              <h3>VW Taigo</h3>
              <img src="/vehiclestaigo.jpg" alt="" />
              <h4>SUV</h4>
            </div>
            <div className="card">
              <h3>Hyundai KONA</h3>
              <img src="/vehicleskona.png" alt="" />
              <h4>SUV</h4>
            </div>
            <div className="card">
              <h3>Renault Clio</h3>
              <img src="/vehiclesclio.png" alt="" />
              <h4>City Car</h4>
            </div>
            <div className="card">
              <h3>Škoda Octavia</h3>
              <img src="/vehiclesoctavia.png" alt="" />
              <h4>Limo</h4>
            </div>
            <div className="card">
              <h3>Mercedes V-Class</h3>
              <img src="/vehiclesvclass.png" alt="" />
              <h4>Van</h4>
            </div>
          </div>
          <button><a href="vehicles"><FormattedMessage id="vehicles.button" /></a></button>
        </section>

      </Component.Grid>

      <Component.Grid>
        <section class="hotel-promo" style={{ backgroundImage: `url('/lounge.png')` }}>
          <h2><FormattedMessage id="hotel.headline" /></h2>
          <p><FormattedMessage id="hotel.subhead1" /></p>
          <p><FormattedMessage id="hotel.subhead2" /></p>
          <a href="https://livrishotel.com" target="_blank"><FormattedMessage id="hotel.button" /></a>
        </section>
      </Component.Grid>

      <Component.Grid>
        <section class="reasons">
          <div class="section-header">
            <h2><FormattedMessage id="reasons.headline" /></h2>
          </div>
          <div class="card-container">
            <div class="reason-card">
              <h3><FormattedMessage id="reasons.subhead1" /></h3>
              <p><FormattedMessage id="reasons.subdesc1" /></p>
            </div>
            <div class="reason-card">
              <h3><FormattedMessage id="reasons.subhead2" /></h3>
              <p><FormattedMessage id="reasons.subdesc2" /></p>
            </div>
            <div class="reason-card">
              <h3><FormattedMessage id="reasons.subhead3" /></h3>
              <p><FormattedMessage id="reasons.subdesc3" /></p>
            </div>
          </div>
        </section>
      </Component.Grid >

      <Component.Grid>
        <div className="news">
          <h2><FormattedMessage id="news.headline" /></h2>
          <h3 className="headline">
            <FormattedMessage id="news.subhead" />
            <br />
            <FormattedMessage id="news.subhead2" />
          </h3>
          <div className="news-container">
            <div className="card">
              <img src="clionews.jpeg" alt="" />
              <div className="content">
                <h3><FormattedMessage id="news.card1.title" /></h3>
                <p>
                  <FormattedMessage id="news.card1.desc" />
                </p>
              </div>
            </div>
            <div className="card">
              <img src="konanews.jpeg" alt="" />
              <div className="content">
                <h3><FormattedMessage id="news.card2.title" /></h3>
                <p>
                  <FormattedMessage id="news.card2.desc" />
                </p>
              </div>
            </div>
            <div className="card">
              <img src="bled.png" alt="" />
              <div className="content">
                <h3><FormattedMessage id="news.card3.title" /></h3>
                <p>
                  <FormattedMessage id="news.card3.desc" />
                </p>
              </div>
            </div>
            <div className="card">
              <img src="plitvice.jpg" alt="" />
              <div className="content">
                <h3><FormattedMessage id="news.card4.title" /></h3>
                <p>
                  <FormattedMessage id="news.card4.desc" />
                </p>
              </div>
            </div>
          </div>
        </div>
      </Component.Grid>
    </>
  );
}

Home.propTypes = {
  code: PropTypes.node,
  language: PropTypes.oneOf(['hr', 'en']).isRequired,
  expired: PropTypes.bool,
  thankYou: PropTypes.bool,
};

Home.defaultProps = {
  code: false,
  expired: false,
  thankYou: false,
};

export default Home;
