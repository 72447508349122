import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Icon from '@mdi/react';
import * as Path from '@mdi/js';
import * as Component from '../components';
// import * as Helper from '../helpers';

function LongTermRental({ language }) {
  const [currentQuestion, setCurrentQuestion] = useState(null);
  // const [vehicles, setVehicles] = useState([]);
  // const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   (async () => {
  //     try {
  //       const result = await Helper.Api.get('vehicles', { params: { longTerm: true } });
  //       setVehicles(result.data);
  //       setLoading(false);
  //     } catch (error) {
  //       console.log(error.response);
  //     }
  //   })();
  // }, []);

  // if (loading) return <Component.Loader />;

  // if (language === 'hr') {
  //   return (
  //     <>
  //       <Component.Scroll />
  //       <Component.Container grid>
  //         <Component.Grid>
  //           <h1 className="title" style={{ margin: '2rem 0 0 0' }}>
  //             <FormattedMessage id="title.longTermRental" />
  //           </h1>
  //         </Component.Grid>
  //       </Component.Container>
  //       {/* {vehicles.length && ( */}
  //       {/*  <div style={{ marginTop: '-2rem' }}> */}
  //       {/*    <Component.Container grid> */}
  //       {/*      {vehicles.map((vehicle) => ( */}
  //       {/*        <Component.Grid key={vehicle.id} medium={6} large={4}> */}
  //       {/*          <Component.LongTermVehicle data={vehicle} /> */}
  //       {/*        </Component.Grid> */}
  //       {/*      ))} */}
  //       {/*    </Component.Container> */}
  //       {/*  </div> */}
  //       {/* )} */}
  //       {/* <Component.Container grid>
  //         <Component.Grid>
  //           <p>* Cijene dugoročnog najma su primjenjive izvan turističke sezone (1.10. - 1.4.) za najam preko 30 dana.</p>
  //         </Component.Grid>
  //       </Component.Container> */}
  //       {/* <Component.Grid>
  //         <Component.Container>
  //           <div className="new-rules contact-details">
  //             <ul>
  //               <li>Dugoročni najam je namjenjen i fizičkim i pravim osobama te su uvijeti potpuno isti, potrebna vam je samo vozačka dozvola.</li>
  //               <li>Korisnici odabiru dugoročni najam prije nego leasing jer vozilo mogu dobiti odmah.</li>
  //               <li>Nije potrebno uplaćivati učešće za vozilo i nositi raznu dokumentaciju kao kod leasinga, nego samo platiti deposit koji je najčešće u visini jedne mjesečne najamnine.</li>
  //               <li>Najamnina pokriva sve troškove vozila: osiguranja, održavanje, asistencije, pomoć na cesti i zamjensko vozilo.</li>
  //             </ul>
  //             <h3>Mjesečna najamnina bez PDV-a. Ponuda vrijedi do 31.05.2024.</h3>
  //             <table>
  //               <tr>
  //                 <td />
  //                 <td>do 2000 km</td>
  //                 <td>do 3000 km</td>
  //               </tr>
  //               <tr>
  //                 <td>
  //                   <img src="https://office.livcar.hr/storage/images/vehicles/j2c2QhfkVUSfocvXIAem52rZNLHEaYPQQAod55VV.jpeg" alt="Corsa" />
  //                   <h4>Opel Corsa ili sl.</h4>
  //                   <h5>ručni</h5>
  //                 </td>
  //                 <td>380€</td>
  //                 <td>470€</td>
  //               </tr>
  //               <tr>
  //                 <td>
  //                   <img src="https://office.livcar.hr/storage/images/vehicles/4B74583SyFEp8D8zbo7m8ah158aJI9YLBbpYQuZo.jpeg" alt="Sandero" />
  //                   <h4>Dacia Sandero ili sl.</h4>
  //                   <h5>ručni</h5>
  //                 </td>
  //                 <td>430€</td>
  //                 <td>520€</td>
  //               </tr>
  //               <tr>
  //                 <td>
  //                   <img src="https://office.livcar.hr/storage/images/vehicles/c0Q7zRUbJCo4oc53MHEOIMTO6GjOJ7qqMh1edoJu.jpeg" alt="i30" />
  //                   <h4>Hyundai i30 ili sl.</h4>
  //                   <h5>ručni</h5>
  //                 </td>
  //                 <td>480€</td>
  //                 <td>570€</td>
  //               </tr>
  //               <tr>
  //                 <td>
  //                   <img src="https://office.livcar.hr/storage/images/vehicles/qHjGkS2vFHG4cOS5gQMiG7bsOLlbOS5SPInK6vDI.jpeg" alt="Kamiq SUV" />
  //                   <h4>Škoda Kamiq SUV</h4>
  //                   <h5>automatski</h5>
  //                 </td>
  //                 <td>520€</td>
  //                 <td>610€</td>
  //               </tr>
  //               <tr>
  //                 <td>
  //                   <img src="https://m.atcdn.co.uk/vms/media/7ba86ea1198546ab9626fa47b8778290.jpg" alt="VW Taigo" />
  //                   <h4>VW Taigo SUV</h4>
  //                   <h5>automatski</h5>
  //                 </td>
  //                 <td>550€</td>
  //                 <td>650€</td>
  //               </tr>
  //               <tr>
  //                 <td>
  //                   <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQiuqL4265BgbqKL12YyuQGVF0_Vu-AoHcQoQ&usqp=CAU" alt="Kodiaq" />
  //                   <h4>Škoda Kodiaq SUV</h4>
  //                   <h5>automatski</h5>
  //                 </td>
  //                 <td>650€</td>
  //                 <td>790€</td>
  //               </tr>
  //             </table>
  //           </div>
  //         </Component.Container>
  //       </Component.Grid> */}
  //       <Component.Container grid>
  //         <Component.Grid>
  //           <div className="contact-details" style={{ margin: '-2rem 0 4rem 0' }}>
  //             <h2 style={{ textAlign: 'left' }}>Kontaktirajte nas i odaberite najbolju opciju za Vas</h2>
  //             <div className="item">
  //               <Icon path={Path.mdiMapMarker} />
  //               <span>Rapska 12, 10000, Zagreb</span>
  //             </div>
  //             <div className="item">
  //               <Icon path={Path.mdiPhoneClassic} />
  //               <span>+385 98 633 893</span>
  //             </div>
  //             <div className="item">
  //               <Icon path={Path.mdiEmail} />
  //               <span>info@livcar.hr</span>
  //             </div>
  //           </div>
  //           <h1 className="title" style={{ margin: '2rem 0' }}>
  //             Prednosti u odnosu na leasing
  //           </h1>
  //           <div className="panel" style={{ marginBottom: '2rem' }}>
  //             <ul>
  //               <li>Uključeno je osnovno i kasko osiguranje</li>
  //               <li>Ne plaćate troškove registracije i tehničkog pregleda</li>
  //               <li>Pokriven je i porez na cestovna motorna vozila</li>
  //               <li>Pokriveni su i troškovi redovnog i izvanrednog održavanja vozila</li>
  //               <li>Pružamo Vam asistenciju i pomoć na cesti i zamjensko vozilo</li>
  //               <li>Imate potpunu fleskibilnost povrata vozila nakon 30 dana</li>
  //             </ul>
  //           </div>

  //           <div className="questions">
  //             <Component.Question
  //               id={0}
  //               question="Održavanje"
  //               answer="<p>Redovita održavanja naša su dužnost. Iskoristite vrijeme, a nama prepustite brigu o svom voznom parku.</p>"
  //               open={currentQuestion === 0}
  //               setOpen={setCurrentQuestion}
  //             />
  //             <Component.Question
  //               id={1}
  //               question="Registracija"
  //               answer="<p>Registracija vozila i tehnički pregled su naša briga.</p>"
  //               open={currentQuestion === 1}
  //               setOpen={setCurrentQuestion}
  //             />
  //             <Component.Question
  //               id={2}
  //               question="Osiguranje"
  //               answer="<p>Pokriće vozila i uvjeti osiguranja se formiraju po Vašim potrebama i mogućnostima.</p>"
  //               open={currentQuestion === 2}
  //               setOpen={setCurrentQuestion}
  //             />
  //             <Component.Question
  //               id={3}
  //               question="Administracija"
  //               answer="<p>Nemate brigu o propisima i regulativi jer svu administraciju pokrivamo mi.</p>"
  //               open={currentQuestion === 3}
  //               setOpen={setCurrentQuestion}
  //             />
  //             <Component.Question
  //               id={4}
  //               question="Kontrola troškova"
  //               answer="<p>Potpuno kontrolirate troškove koji su mjesečno na uvijek istoj razini.</p>"
  //               open={currentQuestion === 4}
  //               setOpen={setCurrentQuestion}
  //             />
  //           </div>
  //         </Component.Grid>
  //       </Component.Container>
  //     </>
  //   );
  // }

  return (
    <>
      {/* <Component.Scroll />
      <Component.Container grid>
        <Component.Grid>
          <h1 className="title" style={{ margin: '2rem 0 0 0' }}>
            <FormattedMessage id="title.longTermRental" />
          </h1>
        </Component.Grid>
      </Component.Container> */}
      {/* {vehicles.length && ( */}
      {/* <div style={{ marginTop: '-2rem' }}> */}
      {/*  <Component.Container grid> */}
      {/*    {vehicles.map((vehicle) => ( */}
      {/*      <Component.Grid key={vehicle.id} medium={6} large={4}> */}
      {/*        <Component.LongTermVehicle data={vehicle} /> */}
      {/*      </Component.Grid> */}
      {/*    ))} */}
      {/*  </Component.Container> */}
      {/* </div> */}
      {/* )} */}
      {/* <Component.Container grid>
        <Component.Grid>
          <p>* Long-term rental prices are only valid outside peak season (1.10. - 1.4.) for rentals longer than 30 days.</p>
        </Component.Grid>
      </Component.Container> */}
      {/* <Component.Container grid>
        <Component.Grid>
          <div className="contact-details" style={{ margin: '-2rem 0 4rem 0' }}>
            <h2 style={{ textAlign: 'left' }}>Get in Touch and Pick the Best Option for You</h2>
            <div className="item">
              <Icon path={Path.mdiMapMarker} />
              <span>Rapska 12, 10000, Zagreb</span>
            </div>
            <div className="item">
              <Icon path={Path.mdiPhoneClassic} />
              <span>+385 98 633 893</span>
            </div>
            <div className="item">
              <Icon path={Path.mdiEmail} />
              <span>info@livcar.hr</span>
            </div>
          </div>
          <h1 className="title" style={{ margin: '2rem 0' }}>
            Advantages over leasing
          </h1>
          <div className="panel" style={{ marginBottom: '2rem' }}>
            <ul>
              <li>Basic and full insurance are included</li>
              <li>You don&apos;t pay for technical inspection and registration</li>
              <li>Vehicle and road taxes are included</li>
              <li>Regular and outstanding maintenance fees are included</li>
              <li>We offer road assistance and replacement vehicles</li>
              <li>Full return flexibility after 30 days</li>
            </ul>
          </div>
          <div className="questions">
            <Component.Question
              id={0}
              question="Maintenance"
              answer="<p>Regular maintenance is our duty. Take your time and let us take care of our fleet.</p>"
              open={currentQuestion === 0}
              setOpen={setCurrentQuestion}
            />
            <Component.Question
              id={1}
              question="Registration"
              answer="<p>Vehicle registration and technical inspection are our concern.</p>"
              open={currentQuestion === 1}
              setOpen={setCurrentQuestion}
            />
            <Component.Question
              id={2}
              question="Insurance"
              answer="<p>Vehicle coverage and insurance conditions are formed according to your needs and capabilities.</p>"
              open={currentQuestion === 2}
              setOpen={setCurrentQuestion}
            />
            <Component.Question
              id={3}
              question="Administration"
              answer="<p>You don’t have to worry about regulations because we are covered by all the administration.</p>"
              open={currentQuestion === 3}
              setOpen={setCurrentQuestion}
            />
            <Component.Question
              id={4}
              question="Expense Control"
              answer="<p>You are in complete control of the expenses, which are at the same level every month.</p>"
              open={currentQuestion === 4}
              setOpen={setCurrentQuestion}
            />
          </div>
        </Component.Grid>
      </Component.Container> */}

      <div className="longtermdiv">
        <div className="panel">
          <h2>Prednosti dugoročnog najma vozila:</h2>
          <ul>
            <li><span class="checkmark">✓</span> Fleksibilnost: Mogućnost izbora vozila prema trenutnim potrebama bez dugoročnog ulaganja.</li>
            <li><span class="checkmark">✓</span> Smanjeni troškovi: Nema troškova održavanja, osiguranja i registracije, što olakšava budžetiranje. Fiksne mjesečne rate olakšavaju planiranje troškova bez iznenađenja.</li>
            <li><span class="checkmark">✓</span> Novi automobili: Redovita promjena vozila znači uvijek imati moderno i pouzdano vozilo.</li>
            <li><span class="checkmark">✓</span> Manje administrativnih obaveza: Većina leasing kompanija preuzima odgovornost za registraciju i održavanje.</li>
            <li><span class="checkmark">✓</span> Porezne olakšice: Moguće porezne beneficije, posebno za poslovne korisnike.</li>
            <li><span class="checkmark">✓</span> Testiranje vozila: Idealno za isprobavanje različitih modela bez obaveze dugoročnog vlasništva.</li>
            <li><span class="checkmark">✓</span> Bez brige o prodaji: Na kraju najma ne morate brinuti o prodaji vozila ili gubitku vrijednosti.</li>
            <li><span class="checkmark">✓</span> Usluge na raspolaganju: Često uključene dodatne usluge poput ceste pomoći ili zamjenskog vozila.</li>
            <li><span class="checkmark">✓</span> Prilagodba potreba: Mogućnost prilagodbe tipa vozila prema sezoni ili promjenama u životnim okolnostima.</li>
          </ul>


        </div>
      </div>
      <div className="offer">
        <h2>Izdvojeno iz ponude za dugoročni najam:</h2>
        <div className="cards">
          <div className="card">
            <h3 className="title">Dacia Sandero</h3>
            <h4 className="type">Gradski - ručni mjenjač</h4>
            <p className="info">Minimalno 30 dana <br />Do 3000 km</p>
            <div className="image"></div>
            <p className="price">14,50€ dan</p>
            <p className="vat">bez PDV-a<br />Sa punim osiguranjem</p>
            <button><a href="/contact"><b>Pošalji upit</b></a></button>
          </div>
          <div className="card">
            <h3 className="title">Hyundai KONA</h3>
            <h4 className="type">SUV - ručni mjenjač</h4>
            <p className="info">Minimalno 30 dana <br />Do 3000 km</p>
            <div className="image"></div>
            <p className="price">18,00€ dan</p>
            <p className="vat">bez PDV-a<br />Sa punim osiguranjem</p>
            <button><a href="/contact"><b>Pošalji upit</b></a></button>
          </div>
          <div className="card">
            <h3 className="title">VW Taigo</h3>
            <h4 className="type">SUV - automatski mjenjač</h4>
            <p className="info">Minimalno 30 dana <br />Do 3000 km</p>
            <div className="image"></div>
            <p className="price">19,50€ dan</p>
            <p className="vat">bez PDV-a<br />Sa punim osiguranjem</p>
            <button><a href="/contact"><b>Pošalji upit</b></a></button>
          </div>
          <div className="card">
            <h3 className="title">Škoda Octavia</h3>
            <h4 className="type">Limo - automatski mjenjač</h4>
            <p className="info">Minimalno 30 dana <br />Do 3000 km</p>
            <div className="image"></div>
            <p className="price">26,13€ dan</p>
            <p className="vat">bez PDV-a<br />Sa punim osiguranjem</p>
            <button><a href="/contact"><b>Pošalji upit</b></a></button>
          </div>
        </div>
      </div>
    </>
  );
}

LongTermRental.propTypes = {
  language: PropTypes.oneOf(['hr', 'en']).isRequired,
};

export default LongTermRental;
