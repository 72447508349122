import React from 'react';
// import PropTypes from 'prop-types';
import spacetime from 'spacetime';
// import Icon from '@mdi/react';
// import * as Path from '@mdi/js';
import '../styles/footer.css';
import { FormattedMessage } from 'react-intl';
// import { Link } from '@reach/router';

const year = spacetime(new Date()).year();

function Footer() {
  return (
    <footer>
      <div className="container">
        <div className="card">
          <i className="fa-solid fa-clock" />
          <div className="content">
            <h2>Zagreb: Livris Hotel</h2>
            <h3>Rapska 12</h3>
            <h3>Pon - Ned</h3>
            <h3>08:00 - 20:00</h3>
            <br />
            <h2>Zračna luka Dubrovnik</h2>
            <h3>Dobrota 24, 20213 Močići</h3>
            {/* <h3>Pon - Ned</h3>
            <h3>08:00 - 20:00</h3> */}
          </div>
        </div>
        <div className="card">
          <i className="fa-solid fa-phone" />
          <div className="content support">
            <h2><FormattedMessage id="footer.reservations" /></h2>
            <h3>+385 98 633 893</h3>
            <hr />
            <h2><FormattedMessage id="footer.support" /></h2>
            <h3>+385 91 6200 700</h3>
          </div>
        </div>
        <div className="card">
          <i className="fa-solid fa-envelope" />
          <div className="content">
            <h2>Email</h2>
            <h3>info@livcar.hr</h3>
          </div>
        </div>
        <div className="card">
          <i className="fa-solid fa-bullhorn" />
          <div className="content contact">
            <h2><FormattedMessage id="footer.opinion" /></h2>
            <h3><a href="/contact"><FormattedMessage id="footer.contact" /></a></h3>
            <h3><FormattedMessage id="footer.notice" /></h3>
          </div>
        </div>
      </div>
      <p className="supports"><FormattedMessage id="footer.availability" /></p>
      <div className="icons">
        <i className="fa-brands fa-cc-visa" />
        <i className="fa-brands fa-cc-mastercard" />
        <i className="fa-brands fa-cc-diners-club" />
        <a href="/terms"><FormattedMessage id="footer.terms" /></a>
        <a href="/privacy"><FormattedMessage id="footer.policy" /></a>
        <a href="/">
          Copyright ©&nbsp;
          {year}
          &nbsp;Livcar d.o.o.
        </a>
      </div>
    </footer>

  );
}

// Footer.propTypes = {
//   language: PropTypes.oneOf(['hr', 'en']).isRequired,
// };

export default Footer;
